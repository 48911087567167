import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Modal } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import notification from "../../assets/notification.jpeg";
import HFlightPortion from "./header/headerFlightPortion/HFlightPortion";
import TitlebarImageList from "./ImageList/ImageList";
import plane from "../../assets/plane.png";

const Home = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const location = useLocation(); // Get the location object from react-router-dom
  // Check if the current route is '/home' and set a timeout to open the modal after 5 seconds
  if (location.pathname === "/" && !modalVisible) {
    setTimeout(() => {
      setModalVisible(true);
    }, 300000);
  }

  return (
    <>
      <HFlightPortion />
      <TitlebarImageList />
      <Modal
        style={{
          // backgroundColor:'red',
          // border: "2px solid purple",
          // borderRadius: "10px",
          // height:'auto'
        }}
        // title="Modal Title"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null} // Hide the footer
      >
        {/* <Container>
          <img
            // src="https://via.placeholder.com/150"
            src={plane}
            alt="Example"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
              marginTop: "-10px",
            }}
          />
        </Container> */}

        <Typography sx={{ color: "#000", fontSize: "17px", fontWeight: 400 }}>
          In Search of Unbeatable Deals?
        </Typography>
        <Typography sx={{ color: "#333", fontSize: "14px", mt: "20px" }}>
          Connect with our friendly team to discover exclusive prices you won't
          find advertised anywhere else. We're ready to offer you a variety of
          travel options, all with our guaranteed lowest price!
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ color: "#333", fontSize: "14px", mt: "20px" }}>
              What are you waiting for?
            </Typography>
            <Typography sx={{ color: "#333", fontSize: "14px", mt: "20px" }}>
              Exclusive travel offers await you.
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ color: "#000", fontSize: "14px", mt: "20px" }}>
                Call{" "}
              </Typography>
              <a
                href="tel:+442081294306"
                style={{
                  marginTop: "12px",
                  marginLeft: "3px",
                  textDecoration: "none",
                  color: "inherit",
                  fontWeight: 700,
                  fontSize: "15px",
                  color: "#e30425",
                  marginRight: "3px",
                  paddingTop: "3px",
                }}
              >
                +44 2081 294306
              </a>
              <Typography sx={{ color: "#000", fontSize: "14px", mt: "20px" }}>
                {" "}
                for free!{" "}
              </Typography>
            </Box>
            <Typography sx={{ color: "#333", fontSize: "14px", mt: "10px" }}>
              Guaranteed best prices.
            </Typography>
            <Typography sx={{ color: "#333", fontSize: "12px", mt: "5px" }}>
              (Open Monday - Saturday, 9:00 AM to 6:00 PM)
            </Typography>

            <Button
              sx={{ background: "purple", color: "#ffffff", mt: "30px" }}
              onClick={() => setModalVisible(false)}
            >
              May be Later !
            </Button>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <img
              // src="https://via.placeholder.com/150"
              src={notification}
              alt="Example"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
                marginTop: "20px",
              }}
            />
          </Grid> */}
        </Grid>
      </Modal>
    </>
  );
};

export default Home;
